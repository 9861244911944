<style lang='less'>
</style>
<template>
  <div class="search-list-vue frame-page h-panel">
    <div class="h-panel-bar"><span class="h-panel-title">道友列表</span></div>
    <div class="h-panel-bar">
	  <div style="padding: 10px 0 20px;">
		  <Search :width="180" v-model="cx.qq" @search="get()" showSearchButton></Search>&nbsp;
		  <Button color="yellow" @click="model2=true">添加道友</Button>
		  </div>
    </div>

	<div class="h-panel-body">
	  <Table :loading="loading" :datas="row.data">
	    <TableItem title="序号" :width="50">
	      <template v-slot="{ index }">{{ index + 1 }} </template>
	    </TableItem>
	    <TableItem prop="user" title="道友账号" :width="100"></TableItem>
		<TableItem prop="money" title="灵石数量" :width="100"></TableItem>
		<TableItem prop="endtime" title="最后上线时间" :width="150"></TableItem>
		<TableItem title="操作" :width="100">
			<template v-slot="{ index }"><Button color="primary" @click="up=row.data[index];model1 = true;">赠予</Button> {{ row.data[index].text }} </template>
		</TableItem>
	  </Table>
	  <Space :height="10" />
	  <Pagination v-if="row.total > 0" align="right" v-model="fengye" @change="changePage" />
	</div>
<!-- 	<Loading text="Loading" :loading="loading"></Loading> -->
	
	<Modal v-model="model1">
	      <template #header>
	        正在为道友 {{up.user}} 赠送灵石
	      </template>
	      <div>
			   <Input type="number" v-model="money" placeholder="输入饼干数量" style="width: 100%;"/>
		  </div>
	      <template #footer>
	        <Button color="primary" @click="modalConfirm">确认</Button>
	        <Button @click="model1=false">关闭</Button>
	      </template>
	</Modal>

	<Modal v-model="model2">
	      <template #header>
	        添加道友
	      </template>
	      <div>
			   <Input type="text" v-model="add.user" placeholder="输入道友账号" style="width: 100%;"/>
			   <Input type="text" v-model="add.pass" placeholder="输入道友密码" style="width: 100%;"/>
		  </div>
	      <template #footer>
	        <Button color="primary" @click="adduser">确认</Button>
	        <Button @click="model2=false">关闭</Button>
	      </template>
	</Modal>		
  </div>
</template>
<script>
import utils from '@common/utils';
import Request from '@common/request';
import { message, modal } from 'heyui';

export default {
  data() {
    return {
	  model1: false,
	  model2: false,
	  row:{
		data:[],
	  },
	  add:{
		  user:'',
		  pass:''
	  },
	  up:{},
      loading: true,
	  cx:{
		page:1,
		pagesize: 10,
		total: 0,
		qq:'',
	  },
	  fengye:{
		  page:1,
		  pagesize: 10,
		  total: 0,
	  },
	  money:""
    };
  },
  mounted() {
    this.get();
  },
  watch: {
    // params() {
	   // this.cx.type=this.params.type
	   // this.get();
    // }
  },
  methods: {
    changePage(pageinfo) {
	   console.log(pageinfo)
	   this.cx.page=pageinfo.page
	   this.cx.pagesize=pageinfo.size
	   this.get();
    },
    get: async function () {
      let res = await Request.User.index({cx:this.cx});
      if(res.code==1){
		  this.row=res;
		  this.cx.total=res.total
		  this.fengye.total=res.total
		  console.log(this.row);
		  this.loading = false; 
	  }
    },
	adduser(){
	    this.loading = true;
		if(this.add.user=='' || this.add.pass==''){
			message.error("所有项目不能为空");
		}
		Request.User.add({data:this.add}).then(res=>{
		  if(res.code==1){
			  message.success(res.msg);
			  this.get();
		  }else{
			  message.error(res.msg);
		  }
		});
		this.model2 = false;
		this.loading = false;
		
	},
	modalConfirm() {
	  this.loading = true;
	  Request.User.cz({uid:this.up.uid,money:this.money}).then(res=>{  
		  if(res.code==1){
		  		  message.success(res.msg);
		  		  this.get();
		  }else{
		  	  message.error(res.msg);
		  }
	  });
	  this.model1 = false;
	  this.loading = false;
	},
  },
  computed: {

  }
};
</script>
